<template>
    <div class="recent-case-area pb-100">
        <div class="container">
            <div class="section-title">
                <ul class="section-style">
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                    <li>
                        Our Case Study
                    </li>
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                </ul>
                <h2>Our Realizations and Recent Cases</h2>
            </div>
        </div>
        <div class="recent-slider">
            <carousel
                :autoplay="5000"
                :wrap-around="true"
                :breakpoints="breakpoints"
                ref="carousel"
            >
                <Slide 
                    v-for="project in projects"
                    :key="project.id"
                >
                    <div class="recent-box">
                        <div class="image">
                            <img :src="project.image" alt="image">
                        </div>
                        <div class="content">
                            <h3>
                                <router-link to="/case-study-details">{{project.title}}</router-link>
                            </h3>
                            <p>{{project.desc}}</p>
                        </div>
                    </div>
                </Slide>
            </carousel>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="custom-navigation">
                        <button class="carousel-prev" @click="prev"><img src="../../assets/images/svgs/slider2.svg" alt="svg"></button>
                        <button class="carousel-next" @click="next"><img src="../../assets/images/svgs/slider1.svg" alt="svg"></button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="case-link">
                        <router-link to="/case-study" class="all-case">See All Projects
                            <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/recent.webp" class="recent-shape" alt="image">
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'OurCaseStudy',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        projects: [
            {
                id: 1,
                image: require("../../assets/images/projects/recent1.webp"),
                title: 'Infrastructure Upgrade',
                desc: 'Game Development',
            },
            {
                id: 2,
                image: require("../../assets/images/projects/recent2.webp"),
                title: 'Creative Landing Page',
                desc: 'UI/UX Design',
            },
            {
                id: 3,
                image: require("../../assets/images/projects/recent3.webp"),
                title: 'Analys & Backup Blockchain',
                desc: 'Data security , IT Consultation',
            },
            {
                id: 4,
                image: require("../../assets/images/projects/recent4.webp"),
                title: 'Analys & Backup Blockchain',
                desc: 'Game Development',
            },
            {
                id: 5,
                image: require("../../assets/images/projects/recent5.webp"),
                title: 'Collapse digital painting',
                desc: 'UI/UX Design',
            },
            {
                id: 6,
                image: require("../../assets/images/projects/recent1.webp"),
                title: 'Analys & Backup Blockchain',
                desc: 'Data security , IT Consultation',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1400: {
                itemsToShow: 3.98,
                snapAlign: 'center',
            },
            1500: {
                itemsToShow: 3.9,
                snapAlign: 'center',
            },
        },
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    }
})
</script>

<style lang="scss">
.recent-case-area {
    position: relative;
    .section-title {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    .recent-slider {
        .carousel {
            text-align: left;
            .carousel__viewport  {
                .carousel__slide  {
                    padding: 0 10px;
                }
            }
        }
    }
    .custom-navigation {
        position: relative;
        text-align: start;
        left: 0;
    }
    .case-link {
        text-align: end;
        position: relative;
        top: 0;
        z-index: 9;
        margin-bottom: 0;
        a {
            font-weight: 500;
            color: var(--mainColor);
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                }
            }
        }
    }
    .recent-shape {
        position: absolute;
        top: 110px;
        left: 10%;
    }
}
.recent-box{
    margin-bottom: 30px;
    .image {
        img {
            border-radius: 5px;
        }
    }
    .content {
        padding: 35px 40px;
        background: #F4F8FC;
        border-radius: 0px 0px 5px 5px;
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 0%;
            z-index: -1;
            background: linear-gradient(90deg, #ef5d5d -21.82%, #FF414B 115.92%);
            border-radius: 0px 0px 5px 5px;
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;
        }
        h3 {
            font-size: 22px; 
            a {
                color: var(--headingColor);
            }
        }
        p {
            margin-bottom: 0;
            transition: var(--transition);
        }
    }
    &:hover {
        .content {
            &::before {
                width: 100%;
                opacity: 1;
                visibility: visible;
            }
            h3{
                a {
                    color: var(--whiteColor);
                }
            }
            p {
                color: #EEEEEE;
            }
        }
    }
}
</style>