<template>
    <div :class="['navbar-area navbar-style3', { 'is-sticky': isSticky }]">
        <div class="desktop-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/Upraiss.svg" class="main-logo" alt="logo">
                    </router-link>

                    <div 
                        class="navbar-toggler"
                        @click="toggler = !toggler" :aria-pressed="toggler ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_toggler_state }"
                        v-on:click="button_toggler_state = !button_toggler_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: toggler }">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">
                                    Home
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">Home Demo 1</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/home-demo-2" class="nav-link">Home Demo 2</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/home-demo-3" class="nav-link">Home Demo 3</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">
                                    About us
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/about" class="nav-link">About One</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/about-2" class="nav-link">About Two</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">
                                    Pages
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/pricing" class="nav-link">Pricing</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/team" class="nav-link">Team</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/faq" class="nav-link">FAQ</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/testimonials" class="nav-link">Testimonials</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/login" class="nav-link">LogIn</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/singup" class="nav-link">Register</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/coming-soon" class="nav-link">Coming Soon</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/privacy-policy" class="nav-link">Privacy Policy</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/terms-conditions" class="nav-link">Terms & Conditions</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/services" class="nav-link">Services</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/service-details" class="nav-link">Service Details</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">
                                    Case Study 
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/case-study" class="nav-link">Case Study</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/case-study-details" class="nav-link">Case Study Details</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link dropdown-toggle">
                                    Blog
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/blog" class="nav-link">Blog</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/blog-details" class="nav-link">Blog Details</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">Contact Us</router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="others-options d-flex other-option-style3">
                        <div class="item-nav">
                            <div class="others-options-one d-flex align-items-center">
                                <div class="option-item option-item2">
                                    <div class="header-search">
                                        <a 
                                            class="search-toggle"
                                            @click="active = !active" 
                                            :aria-pressed="active ? 'true' : 'false'"
                                            v-bind:class="{ 'active': button_active_state }"
                                            v-on:click="button_active_state = !button_active_state"
                                        >
                                            <i class=" bx bx-search"></i>
                                            <i class="flaticon-close"></i>
                                        </a>

                                        <form class="search-box" :class="{ search: active }">
                                            <div class="form-group d-flex align-items-center">
                                                <input type="search" name="s" value="" class="search-input" id="search" placeholder="Search">
                                                <button type="submit" class="search-submit"><i class='bx bxs-paper-plane'></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-nav">
                            <router-link to="/login">Log in</router-link>
                        </div>
                        <div class="item-nav">
                            <router-link to="/singup" class="default-btn">Register</router-link>
                        </div>
                    </div>
                </nav>

                <div class="others-option-for-responsive others-option-for-responsive3">
                    <div class="container">
                        <div 
                            class="dot-menu"
                            @click="dot = !dot" 
                            :aria-pressed="dot ? 'true' : 'false'"
                            v-bind:class="{ 'dot': button_dot_state }"
                            v-on:click="button_dot_state = !button_dot_state"
                        >
                            <div class="inner">
                                <div class="circle circle-one"></div>
                                <div class="circle circle-two"></div>
                                <div class="circle circle-three"></div>
                            </div>
                        </div>

                        <div class="container" :class="{ active: dot }">
                            <div class="option-inner">
                                <div class="others-options d-flex others-options3">
                                    <div class="item-nav">
                                        <div class="others-options-one d-flex align-items-center">
                                            <div class="option-item">
                                                <div class="header-search">
                                                    <a 
                                                        class="search-toggle"
                                                        @click="active = !active" 
                                                        :aria-pressed="active ? 'true' : 'false'"
                                                        v-bind:class="{ 'active': button_active_state }"
                                                        v-on:click="button_active_state = !button_active_state"
                                                    >
                                                        <i class=" bx bx-search"></i>
                                                        <i class="flaticon-close"></i>
                                                    </a>

                                                    <form class="search-box" :class="{ search: active }">
                                                        <div class="form-group d-flex align-items-center">
                                                            <input type="search" name="s" value="" class="search-input" id="search" placeholder="Search">
                                                            <button type="submit" class="search-submit"><i class='bx bxs-paper-plane'></i></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-nav">
                                        <router-link to="/login">Log in</router-link>
                                    </div>
                                    <div class="item-nav">
                                        <router-link to="/singup" class="default-btn">Register</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainNavbarTwo',
    data() {
        return {
            isSticky: false,
			toggler: false,
			button_toggler_state: false,
            active: false,
            button_active_state: false,
            dot: false,
            button_dot_state: false,
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
}
</script>

<style lang="scss">
.navbar-area {
	position: relative;
	padding: {
		top: 12px;
		bottom: 12px;
	};
    z-index: 2;
    background-color: var(--whiteColor);
    &.is-sticky {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 4;
        position: sticky;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.07);
	}
	.navbar-brand {
		.white-logo {
			display: none;
		}
	}
} 
.desktop-nav {
    padding: {
        top: 0;
        bottom: 0;
    };
    .navbar {
		transition: all ease .5s;
		z-index: 2;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        };
        ul {
            margin-bottom: 0;
            list-style-type: none;
        }
        .navbar-nav {
            .nav-item {
                position: relative;
                padding: {
                    bottom: 20px;
                    top: 20px;
                    right: 0;
                    left: 0;
                };
                a {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1;
                    position: relative;
                    padding: {
                        left: 0;
                        top: 0;
						right: 0;
                        bottom: 0;
                    };
                    color: #000000;
                    margin: {
                        left: 23px;
                        right: 23px;
                    };
					&.dropdown-toggle {
						padding-right: 20px;
						&::after {
							display: none;
						}
						&::before {
                            content:"\f101";
                            position: absolute;
                            top: 5px;
                            right: 6px;
                            font-family: flaticon !important;
                            font-weight: 900;
                            line-height: 1;
                            font-size: 9px;
						}
					}
                    &:hover, &:focus, &.active {
                        color: var(--mainColor);
                    }
                    i {
						font-size: 18px;
						line-height: 0;
						position: relative;
						top: 4px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &:focus, &.active {
                    a {
                        color: var(--mainColor);
                    }
                }
                .dropdown-menu {
                    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
                    transition: all 0.3s ease-in-out;
                    background: #ffffff;
                    position: absolute;
                    visibility: hidden;
                    border-radius: 10px;
                    display: block;
                    width: 250px;
                    border: none;
                    z-index: 99;
                    opacity: 0;
                    top: 80px;
                    left: 0;
                    padding: 0;
                    li {
                        padding: 0;
                        a {
                            color: var(--blackColor);
                            padding: 16px;
							border-bottom: 1px dashed #e3e3e3;
							margin: 0;
							font-size: 15px;
							font-weight: 500;
							line-height: 1;
							display: block;
							position: relative;
							&.dropdown-toggle {
								&::after {
									display: none;
								}
								&::before {
									content:"\f133";
									position: absolute;
									top: 15px;
									right: 13px;
                                    font-family: flaticon_edly !important;
									line-height: 1;
									font-size: 10px;
    
								}
							}
                            &:hover, &:focus, &.active {
                                color: var(--mainColor);
                            }
                        }
                        .dropdown-menu {
                            left: 250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            li {
                                a {
                                    color: #1b2336;
                                    border-bottom: 1px dashed #e3e3e3;
                                    font-size: 17px;
									font-weight: 600;
									line-height: 1;
									position: relative;
									&.dropdown-toggle {
										&::after {
											display: none;
										}
										&::before {
											content:"\EA4E";
											position: absolute;
											top: 14px;
											right: 13px;
											line-height: 1;
											font-size: 17px;
										}
									}
                                    &:hover, &:focus, &.active {
										color: var(--mainColor);
                                    }
                                }
                                .dropdown-menu {
                                    left: 250px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: #000000;
                                            &:hover, &:focus, &.active {
                                                color: #1b2336;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                                            li {
                                                a {
                                                    color: #000000;
                                                    
                                                    &:hover, &:focus, &.active {
                                                        color: #1b2336;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    li {
                                                        a {
                                                            color: #000000;
                                                            
                                                            &:hover, &:focus, &.active {
                                                                color: #1b2336;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            li {
                                                                a {
                                                                    color: #000000;
                                                                    
                                                                    &:hover, &:focus, &.active {
																		color: #1b2336;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    li {
                                                                        a {
                                                                            color: #000000;
                                                                            &:hover, &:focus, &.active {
                                                                                color: #1b2336;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: #1b2336;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: #1b2336;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -1px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: #00ca99;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        top: -1px;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: #00ca99;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                top: -15px;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: #00ca99;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: #00ca99;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: 0;
                            }
						}
						&:last-child {
							a {
								border-bottom: none;
							}
						}
                    }
                }
                &:hover {
                    .dropdown-menu {
                        top: 100%;
                        opacity: 1;
                        margin-top: 10px;
                        visibility: visible;
                    }
				}
            }
        }
    }
    
}
@keyframes fadeInDown {
	0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
	}
	to {
        opacity: 1;
        transform: translateZ(0);
	}
}

/* Navbar toggler */
.navbar-toggler {
    border: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    &:focus {
        box-shadow: none !important;
    }
    .icon-bar {
        width: 35px;
        transition: all 0.3s;
        background: var(--mainColor);
        height: 4px;
        display: block;
    }
    .top-bar {
        transform: rotate(0); 
        transform-origin: 10% 10%;
        position: relative;
    }
    .middle-bar {
        opacity: 1;
        margin: 5px 0;
    }
    .bottom-bar {
        transform: rotate(0);
        transform-origin: 10% 90%;
        position: relative;
    }
    &.active {
        .top-bar {     
            transform: rotate(45deg);
        }
        .middle-bar {
            opacity: 0;
        }
        .bottom-bar {
            transform: rotate(-45deg);
        }
    }
}

.others-option-for-responsive {
	display: none !important;
	.dot-menu {
		padding: 0 10px;
		height: 30px;
		cursor: pointer;
		z-index: 999;
		position: absolute;
		right: 60px;
        top: 0;
		.inner {
			display: flex;
			align-items: center;
			height: 30px;
			.circle {
				height: 5px;
				width: 5px;
				border-radius: 100%;
				margin: 0 2px;
				transition: all ease .5s;
				background-color: var(--mainColor);
			}
		}
		&:hover {
			.inner {
				.circle {
					background-color: var(--mainColor);
				}
			}
		}
	}
	.container {
		position: relative;
		.container {
			position: absolute;
			right: -2px;
			top: 45px;
			max-width: 320px;
			margin-left: auto;
			opacity: 0;
			visibility: hidden;
			transition: .5s;
			transform: scaleY(0);
			padding-left: 15px;
			padding-right: 15px;
		}
		.container.active {
			opacity: 1;
			visibility: visible;
            transform: scaleY(1);
            z-index: 2;
        }
        .others-options {
            background-color: var(--whiteColor);
            padding: 12px 8px 12px 8px;
            border-radius: 10px;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            width: 290px;
            right: 15px;

            a.common-btn.style1 {
                position: relative;
            }
            a.login-text {
                position: relative;
                font-family: 'Josefin Sans';
                font-style: normal;
                font-weight: 700;
                top: 5px;
                font-size: 13px;
                text-transform: uppercase;
                color: #049370;
            }
            .optional-btn {
                color: var(--mainColor);
                margin-right: 25px;
                margin-left: 0;
                font-weight: 700;
                position: relative;
                top: 2px;
                padding: 14px 25px;
            }
            .default-btn {
                font-size: 15px;
            }
        }
    }
}
.search-popup {
    position: absolute;
    top: 28px;
    width: 350px;
    right: 0;
    z-index: 2;
    margin-top: 30px;
    .search-form {
        position: relative;
        .search-input {
            display: block;
            width: 100%;
            height: 50px;
            border: none;
            outline: 0;
            padding: 15px 60px 15px 25px;
            border-radius: 30px;
            background-color: #f2f2f2;
            &::-webkit-input-placeholder {
                color: var(--paragraph-color);
                -webkit-transition: var(--transition);
                transition: var(--transition);
            }
            &:-ms-input-placeholder {
                color: var(--paragraph-color);
                -webkit-transition: var(--transition);
                transition: var(--transition);
            }
            &::-ms-input-placeholder {
                color: var(--paragraph-color);
                -webkit-transition: var(--transition);
                transition: var(--transition);
            }
            &::placeholder {
                color: var(--paragraph-color);
                -webkit-transition: var(--transition);
                transition: var(--transition);
            }
            &:focus {
                border-color: var(--main-color);
                &::-webkit-input-placeholder {
                    color: transparent;
                }
                &:-ms-input-placeholder {
                    color: transparent;
                  }
            }
        }
        .search-button {
            position: absolute;
            right: 5px;
            top: 4.5px;
            height: 40px;
            width: 40px;
            line-height: 42px;
            background-color: var(--white-color);
            border: none;
            -webkit-transition: var(--transition);
            transition: var(--transition);
            color: var(--main-color);
            border-radius: 30px;
            font-size: 18px;
            &:hover {
                background-color: var(--main-color);
                color: var(--white-color);
            }
            &:focus {
                background-color: var(--main-color);
                color: var(--white-color); 
            }
        }
    }
}
.others-options-one {
    position: relative;
    top: -6px;
    
    .option-item2 {
        .bx-search2 {
            top: 4px !important;
            position: relative;
        }
    }
    .search-btn {
        cursor: pointer;
        font-size: 30px;
        position: relative;
        top: 11px !important;
    }
    .close-btn {
        font-size: 30px;
        display: none;
        cursor: pointer;
        position: relative;
        top: 2px !important;
        margin-bottom: 6px;
        &.active {
            display: block;
            transition: var(--transition);
        }
    }
    .searchus-form {
        position: relative;
        background: var(--whiteColor);
        padding: 20px;
        box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.07);
        .from-control {
            height: 45px;
            width: 100%;
            padding: 15px;
            border: none;
            background-color: #f5f5f5;
            position: relative;
            transition: var(--transition);
            &:focus {
                box-shadow: unset;
            }
            &:focus-visible {
                outline: 0;
            }
        }
        .search-button {
            position: absolute;
            right: 85px;
            top: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: none;
            background: transparent;
            i {
                font-size: 23px;
                position: relative;
                top: -1px;
                right: -66px;
                color: #555555;
                line-height: 39px;
                height: 36px;
                width: 40px;
                background: #fff;
            }
        }
    }
}
.others-options {
    align-items: center;
    top: 5px;
    position: relative;
    .item-nav {
        display: inline-block;
        padding-left: 40px;
            i {
                font-size: 28px;
                position: relative;
                cursor: pointer;
            }
        .icon {
            position: relative;
            width: 52.5px;
            height: 52.5px;
            background: rgba(161, 136, 88, 0.1);
            display: inline-block;
            border-radius: 50%;
            text-decoration: none;
            top: -5px;
            span {
                padding-left: 5px;
                position: absolute !important;
                top: 19px !important;
                border-radius: 50% !important;
                height: 24px;
                left: 37px !important;
                width: 24px;
                line-height: 17px;
                background: #FF2A00 !important;
            }
            i {
                position: relative;
                top: 11px;
                left: 10px;
            }
        }
        &:last-child {
            position: relative;
            z-index: 1;
            margin-left: 25px;
            margin-right: 26px;
            &:before {
                content:"";
                position: absolute;
                top: -24px;
                left: 15px;
                bottom: 0px;
                right: 0;
                width: 80px;
                z-index: -1;
                height: 80px;
                background-color: var(--hoverColor);
            }
            i {
                top: -1px;
                color: var(--whiteColor);
            }
        }
    }
}
.navbar-style3 {
    padding-top: 22px;
    padding-bottom: 22px;
}
.other-option-style3 {
    top: 0;
    .item-nav {
        padding-left: 25px !important;

        &:first-child {
            padding-left: 0 !important;
        }
        &:last-child {
            &::before {
                display: none;
            }
            margin-left: 0;
            margin-right: 0;
        }
        .default-btn {
            padding: 15px 35px;
            color: var(--whiteColor);
            &:hover {
                color: var(--whiteColor);
            }
        }
        a {
            font-weight: 500;
            font-size: 16px;
            color: #2E2F46;
            &:hover {
                color:var(--mainColor);
            }
        }
    }
    .others-options-one {
        .option-item2 {
            .bx-search2 {
                top: 12px !important;
                position: relative !important;
            }
        }
        .close-btn {
            top: 13px !important;
        }
    }
}
.header-search {
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    .search-box {
        &.search {
            opacity: 1;
            width: 400px;
            visibility: visible;
            &::before {
                left: auto;
                right: 4px;
                top: -5px;
                z-index: -1;
                content:"";
                width: 20px;
                height: 20px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                border-radius: 2px;
                background: #FFF;
                transform: rotate(45deg);
            }
        }
    }
    .search-toggle {
        cursor: pointer;
        color: #0E1E36;

        i.bx.bx-search {
            position: relative;
            top: 5px;
            transition: var(--transition);
        }
        i.flaticon-close {
            font-size: 15px !important;
            position: absolute !important;
            top: 10px;
            opacity: 0;
            left: 5px;
            transition: var(--transition);
        }
        &:hover {
            color: #4069FF;
        }
        &.active {
            color: #4069FF;
            i.bx.bx-search {
                opacity: 0;
            }
            i.flaticon-close {
                opacity: 1;
            }
        }
    }
    .search-box {
        width: 0;
        left: -160px;
        border: 0;
        opacity: 0;
        z-index: 1;
        padding: 10px 20px;
        border-radius: 4px;
        visibility: hidden;
        position: absolute;
        background: #FFF;
        top: calc(100% + 45px);
        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
        transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
        transform: translateX(-50%);
        .form-group {
            margin: 0;
            padding: 0;
            position: relative;
            &::before {
                left: 0;
                width: 0;
                right: 0;
                bottom: 0;
                content:"";
                height: 1px;
                margin-left: auto;
                background: #25415d;
                margin-right: auto;
                position: absolute;
                -webkit-transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
                transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
              }
            .search-input {
                width: 100%;
                border: 0 !important;
                font-size: 16px !important;
                padding: 10px 5px !important;
                line-height: 22px !important;
                box-shadow: none !important;
                padding-right: 22px !important;
                color: #243342 !important;
                background: transparent !important;
                border-radius: 0 !important;
                &:focus {
                    box-shadow: unset;
                }
                &:focus-visible {
                    outline: 0;
                }
            }
            .search-submit {
                right: 0;
                top: auto;
                border: 0;
                bottom: auto;
                font-size: 16px;
                position: absolute;
                background: transparent;
                -webkit-transition: all all 0.3s ease ease-in-out;
                transition: all all 0.3s ease ease-in-out;
                i {
                    color: var(--hoverColor);
                }
              }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .others-option-for-responsive {
        display: block !important;
        position: absolute;
        top: 25px;
        right: 0;
        .container {
            .container {
                right: 0;
            }
        }
        .others-options {
            .search-form {
                margin-left: 0;
                width: 100%;
                margin-right: 10px;
            }
        }
        .others-options .item-nav {
            display: inline-block;
            padding-left: 8px !important;
        }
        .header-search {
            top: -5px !important;
            .search-box {
                top: calc(100% + 35px) !important;
                left: 110px !important;
                &::before {
                    left: 25px !important;
                    right: auto !important;
                }
            }
        }
    }
}

/* Mobile and iPad Navbar */
@media only screen and (max-width: 991px) {
    a.navbar-brand {
        font-size: 33px;
        font-weight: 900;
        color: #fff;
        span{
            color: #05BB8F;
        }
    }
    .header-search {
        .search-box {
            .form-group {
                .search-submit i {
                    color: #FF414B;
                    top: 0;
                }
            }
        }
    }
    .others-options ul li {
        display: inline-block;
        padding-left: 18px;
        padding-top: 10px;
    }
    .navbar-area {
        padding: {
            top: 10px;
            bottom: 10px;
        };
        .desktop-nav {
            .navbar {
                position: relative;
                .navbar-collapse {
                    max-height: 70vh;
                    overflow-y: scroll;
                    border-top: 1px solid #eee;
                    margin-top: 10px;
                    padding-top: 5px;
                    padding-right: 15px;

                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background: var(--mainColor); 
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: var(--mainColor); 
                    }
                    .navbar-nav {
                        margin-right: 0;
                        .nav-item {
                            margin-left: 0;
                            margin-right: 0;
                            padding: 0;
                            a {
                                font-size: 15px;
                                padding-top: 8px;
                                padding-bottom: 8px;
                                margin-left: 0;
                                margin-right: 0;
                                &:before {
                                    display: none;
                                }
                            }
                            .dropdown-menu {
                                position: relative;
                                top: 0 !important;
                                box-shadow: unset;
                                left: 0;
                                width: 100%;
                                opacity: 1;
                                visibility: visible;
                                border: 1px solid #eee;
                                border-radius: 0;
                                margin-top: 0;
                                padding: 10px;
                                margin-bottom: 10px;
                                li {
                                    a {
                                        padding: 13px 10px;
                                        font-size: 13px;
                                        color: var(--blackColor);
                                    }
                                    .dropdown-menu {
                                        position: relative;
                                        top: 0 !important;
                                        box-shadow: unset;
                                        left: 0;
                                        width:90%;
                                        opacity: 1;
                                        visibility: visible;
                                        border: 1px solid #eee;
                                        border-radius: 0;
                                        margin-top: 0;
                                        padding: 10px;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.is-sticky {
            top: -1px;
            left: 0;
            width: 100%;
            z-index: 3;
            position: sticky;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        }
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
        &.navbar-top {
            transform: translateY(0);
        }
    }
    .others-options-one {
        .searchus-form {
            padding: 12px;
            .from-control {
                height: 16px;
                width: auto;
                padding: 17px 34px;
                border-radius: 0;
                position: relative;
                left: 0px;
                right: 12px;
                transition: all ease 0.8s;
            }
        }
    }
    .others-options-one {
        .searchus-form {
            width: 100%;
        }
    }
    ul.last-nav {
        display: block !important;
    }
    .other-option-style3 {
        position: absolute !important;
        top: 5px !important;
        right: 40px;
        
        .item-nav {
            padding-left: 15px !important;
        }
        .header-search {
            .search-toggle {
                i {
                    &.bx.bx-search {
                        top: 10px !important;
                    }
                    &.flaticon-close {
                        top: 10px !important;
                    }
                }
            }
        }
    }
    .search-popup {
        position: absolute;
        top: 37px;
        width: 265px;
        right: -69px;
        height: 50px;
        z-index: 2;
        margin-top: 25px;
        border-radius: 8px;
    }
    .header-search {
        .search-box {
            width: 0 !important;
            left: -58px !important;
            right: auto;
            opacity: 0;
            z-index: 99;
            padding: 10px 20px;
            border-radius: 4px;
            visibility: hidden !important;
            position: absolute;
            background: #FFF;
            top: calc(100% + 30px) !important;
            filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
            transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
            transform: translateX(-50%);

            .form-group {
                .search-submit {
                    top: 0 !important;
                }
            }
            &.search {
                opacity: 1 !important;
                width: 260px !important;
                visibility: visible !important;
                &::before {
                    left: 0;
                    right: -81px;
                }
            }
        }
        i.bx.bx-search {
            top: 15px !important;
        }
        i.flaticon-close {
            top: 15px !important;
        }
        .search-box {
            left: -20px;
            top: calc(100% + 44px);
        }
    }
    .others-options-one {
        .searchus-form {
            .search-button {
                i {
                    top: 0px;
                    right: -75px;
                }
            }
            .from-control {
                height: 16px;
                width: auto;
                padding: 17px 50px;
                padding-left: 20px;
                border-radius: 0;
                position: relative;
                left: 0px;
                right: 12px;
                transition: all ease 0.8s;
            
            }
        }
    }
    .others-options-one {
        .option-item2 {
            .bx-search2 {
                top: 16px !important;
                position: relative;
            }
        }
    }
    .others-options {
        .item-nav {
            &:last-child {
                margin-left: 10px;
                margin-right: 11px;
                &::before {
                   display: none;
                }
            }
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .others-option-for-responsive {
        display: block !important;
        position: absolute;
        top: 33px;
        right: 0;
        .dot-menu {
            right: 25px;
            top: 0;
        }
        .container {
            .container {
                right: 0;
                position: relative;
            }
        }
        .others-options {
            top: 15px;
            .search-form {
                margin-left: 0;
                width: 100%;
                margin-right: 10px;
            }
        }
        .others-options .item-nav {
            display: inline-block;
            padding-left: 8px !important;
        }
        .header-search {
            top: -5px !important;
            .search-box {
                top: calc(100% + 35px) !important;
                left: 110px !important;
                width: 300px;
                &::before {
                    left: 25px !important;
                    right: auto !important;
                }
            }
            .search-toggle {
                i {
                    &.bx.bx-search {
                        top: 15px !important;
                    }
                    &.flaticon-close {
                        top: 15px !important;
                    }
                }
            }
        }
    }
    .other-option-style3.d-flex {
        display: none !important;
    }
}
</style>