<template>
    <div class="startups-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="single-section startup-content">
                        <ul class="section-style">
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                            <li>
                                Why Choose Us
                            </li>
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                        </ul>
                        <h2>We Helps Startups In The Early Stages.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ulla
                            mattis pulvinar dapibus leoorem ipsum.</p>
                            <div class="choose-box choose-box-style2">
                            <div class="icon icon2">
                                <img src="../../assets/images/svgs/choose1.svg" class="choose" alt="svg">
                                <img src="../../assets/images/svgs/choose-hide1.svg" class="choose-hide" alt="svg">
                            </div>
                            <div class="content">
                                <h4>User Oriented Approach</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitv</p>
                            </div>
                        </div>
                        <div class="choose-box choose-box-style2 two">
                            <div class="icon icon2">
                                <img src="../../assets/images/svgs/choose2.svg" class="choose" alt="svg">
                                <img src="../../assets/images/svgs/choose-hide2.svg" class="choose-hide" alt="svg">
                            </div>
                            <div class="content">
                                <h4>Team Augmentation</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitv</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="startup-image text-center">
                        <img src="../../assets/images/choose/choose-1.webp" alt="image">
                        <div class="banner-funfect">
                            <div class="single-funfact-box">
                                <img src="../../assets/images/svgs/rocket2.svg" class="fun-image" alt="image">
                                <div class="content-fun">
                                    <h3>70+</h3>
                                    <p>Costumer</p>
                                </div>
                                <img src="../../assets/images/shapes/fun.webp" class="fun-shape" alt="image">
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/choose-shape3.webp" class="choose-shape3" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="b-s-shape1" alt="image">
                        <img src="../../assets/images/shapes/b-c-shape.webp" class="b-s-shape2" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/choose-shape2.webp" class="choose-border"  alt="image">
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>

<style lang="scss">
.choose-box-style2 {
    p {
        margin-bottom: 0;
    }
    h4 {
        margin-bottom: 8px !important;
    }
    &.two {
        margin-bottom: 0;
    }
    .icon {
        position: absolute !important;
        width: 70px;
        height: 70px;
        left: 35px;
        border-radius: 50%;
        line-height: 65px;
        text-align: center;
        margin-right: 20px;
        transition: var(--transition);
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            width: 100%;
            opacity: 0;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            background: rgba(0, 100, 251, 0.1);
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            transition: var(--transition);
            background: rgba(255, 65, 75, 0.1);
        }
        .choose-hide {
            position: absolute;
            left: 12px;
            top: 11px;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
        }
        .choose {
            transition: var(--transition);
        }
    }
    .content {
        padding-left: 90px;
    }
}
.startup-content {
    margin-right: 40px;
    p {
        margin-bottom: 30px;
    }
}
.startup-image {
    position: relative;
    z-index: 1;
    .banner-funfect {
        position: absolute;
        bottom: 125px;
        right: -55px;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
        .single-funfact-box {
            background: #ef5d5d;
            position: relative;
            border-radius: 5px;
            padding: 25px 35px 35px 35px;
            .fun-image {
                position: absolute;
                left: 32px;
                top: 51px;
            }
            h3 {
                font-weight: 800;
                font-size: 48px;
                color: var(--whiteColor);
                margin-bottom: 0;
            }
            p {
                color: #EEEEEE;
                margin-bottom: 0;
            }
            .content-fun {
                padding-left: 80px;
    
            }
            .fun-shape {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .choose-shape3 {
        position: absolute;
        bottom: 5px;
        left: 37px;
        z-index: -1;
    }
    .b-s-shape1 {
        position: absolute;
        top: 155px;
        right: 130px;
    }
    .b-s-shape2 {
        position: absolute;
        bottom: 111px;
        right: -40px;
    }
}
.startups-area {
    position: relative;
    .choose-border {
        position: absolute;
        left: 125px;
        bottom: 0;
        z-index: -1;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .choose-area {
        .choose-border {
            display: none;
        }
    }
    .choose-content {
        margin-bottom: 30px;
        padding-left: 0;
    }
    .choose-box {
        padding: 25px 20px;
        margin-bottom: 20px;
        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
        .icon {
            width: 50px;
            height: 50px;
            line-height: 47px;
            margin-right: 10px;
            img {
                height: 30px;
            }
        }
        &.style {
            position: relative;
            right: 0;
        }
    }
    .choose-widget {
        .choose-shape {
            display: none;
        }
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .startup-image {
        .b-s-shape2 {
            right: 0;
        }
        .banner-funfect {
            right: auto;
            bottom: 35px;
        }
        .choose-shape3 {
            left: 0;
            z-index: -1;
        }
    }
    .startups-area {
        .choose-border {
            display: none;
        }
    }
    .choose-box-style2 {
        .content {
            padding-left: 65px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .startups-area {
        .choose-border {
            left: 0;
        }
    }
    .choose-area {
        .choose-border {
            display: none;
        }
    }
    .choose-box.style {
        position: relative;
        right: -75px;
    }
    .choose-content {
        position: relative;
        padding-left: 0;
    }
    .choose-widget {
        margin-left: 0;
        margin-top: 40px;
        .choose-shape {
            animation-name: unset;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .choose-box {
        padding: 30px 15px;
        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
    .choose-area {
        .choose-border {
            left: 0;
        }
    }
    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }
    .choose-box-style2 {
        .content {
            padding-left: 101px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }
    .choose-area {
        .choose-border {
            left: 0;
        }
    }
    .choose-box {
        padding: 25px 20px;
        .content {
            h4 {
                font-size: 20px;
            }
        }
    }
    .startups-area {
        .choose-border {
            left: 0;
        }
    }
    .startup-image {
        .banner-funfect {
            bottom: 125px;
            right: 0;
        }
        .b-s-shape2 {
            right: 34px;
        }
        .choose-shape3 {
            left: 10px;
        }
    }
}
</style>