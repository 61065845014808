<template>
    <div class="banner-area banner-area-style-2">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="banner-content banner-content-style2">
                        <ul class="section-style">
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                            <li>
                                Vnet Best Software Company
                            </li>
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                        </ul>
                        <h1>Our Company Provide Best IT Solutions</h1>
                        <p class="banner-p">Nulla quis feugiat mi. Mauris tincidunt dui massa, non feugiat ex blandit vel. Nam lacinia dapibus nisi accon est tempor ac. Sed ultrices eros id tristique nec fringilla augue dui ac magna.  </p>

                        <div class="banner-btn d-flex">
                            <router-link to="/contact" class="default-btn">
                                Get In Touch
                            </router-link>
                            <router-link to="/singup" class="default-btn default-btn-style2">
                                Sign Up Free
                            </router-link>
                        </div>

                        <p class="link-p2"> <i class='bx bxs-star'></i> Rated 4.9/5 from over 600 reviews. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="banner-image banner-image-style2">
                        <img src="../../assets/images/banner/banner-shape2.webp" class="banner-man" alt="image">
                        <div class="banner-funfect">
                            <div class="single-funfact-box">
                                <img src="../../assets/images/svgs/rocket1.svg" class="fun-image" alt="image">
                                <div class="content-fun">
                                    <h3>650+</h3>
                                    <p>Finish Project</p>
                                </div>
                                <img src="../../assets/images/shapes/fun.webp" class="fun-shape" alt="image">
                                <img src="../../assets/images/shapes/b-c-shape.webp" class="q-a-shape2" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-shape">
                <img src="../../assets/images/shapes/banner-shape1.webp" class="banner-shape-01" alt="image">
                <img src="../../assets/images/shapes/b-s1.webp" class="b-s1" alt="image">
                <img src="../../assets/images/shapes/b-s2.webp" class="b-s2" alt="image">
                <img src="../../assets/images/shapes/b-s3.webp" class="b-s3" alt="image">
                <img src="../../assets/images/shapes/b-s1.webp" class="b-s4" alt="image">
                <img src="../../assets/images/shapes/b-s1.webp" class="b-s5" alt="image">
                <img src="../../assets/images/banner/banner-shape3.webp" class="banner-shape-3" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>

<style lang="scss">
.banner-content-style2 {
    margin-top: 50px;
    .banner-p {
        padding-right: 100px;
        margin-bottom: 25px;
    }
    .banner-btn {
        margin-bottom: 20px;
        .default-btn-style2 {
            margin-left: 20px;
            background-color: transparent;
            color: var(--mainColor);
            border: solid 1px var(--mainColor);
            &:hover {
                background-color: var(--hoverColor);
                color: var(--whiteColor);
                border-color: var(--hoverColor);
            }
        }
        
    }
    .link-p2 {
        margin-bottom: 0;
        i {
            height: 20px;
            width: 20px;
            background-color: var(--starColor);
            text-align: center;
            color: var(--whiteColor);
            font-size: 14px;
            line-height: 20px;
            border-radius: 3px;
            margin-right: 12px;
        }
    }
    h1 {
        margin-bottom: 8px;
        line-height: 105px;
    }
}
.banner-image-style2{
    position: relative;
    .banner-man {
        position: relative;
        bottom: -120px;
        margin-top: -120px;
    }
    .banner-funfect {
        position: absolute;
        bottom: -12px;
        right: 97px;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
        .single-funfact-box {
            background: #ef5d5d;
            position: relative;
            border-radius: 5px;
            padding: 25px 35px 35px 35px;
            .q-a-shape2 {
                position: absolute;
                right: 20px;
                bottom: -15px;
            }
            .fun-image {
                position: absolute;
                left: 32px;
                top: 51px;
            }
            h3 {
                font-weight: 800;
                font-size: 48px;
                color: var(--whiteColor);
                margin-bottom: 0;
            }
            p {
                color: #EEEEEE;
                margin-bottom: 0;
            }
            .content-fun {
                padding-left: 80px;

            }
            .fun-shape {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    
}
.banner-shape-3 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.banner-area-style-2 {
    padding-top: 70px;
    .banner-shape{
        .banner-shape-01 {
            position: absolute;
            top: 49%;
            left: 56%;
        }
        .b-s1 {
            position: absolute;
            top: 10%;
            left: 16%;
        }
        .b-s2 {
            position: absolute;
            left: 32%;
            top: 13%;
        }
        .b-s3 {
            position: absolute;
            left: 47%;
            bottom: 13%;
        }
        .b-s4 {
            position: absolute;
            bottom: 25%;
            left: 39%;
        }
        .b-s5 {
            position: absolute;
            bottom: 10%;
            left: 17%;
        }
    }
}
</style>