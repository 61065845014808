<template>
    <div class="expert-team-area bg-F4F8FC pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <p class="title-tag">
                    <i class="flaticon-full-stop"></i>
                    OUR TEAM MEMBERS
                </p>
                <h2>Meet Our Expert Team</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="expert-box">
                        <img src="../../assets/images/team/member1.webp" alt="image">
                        <div class="latest-content  d-flex">
                            <div class="content">
                                <h5>
                                    <router-link to="/team">
                                        Christian Aaron
                                    </router-link>
                                </h5>
                                <p>Marketing Manager</p>
                            </div>
                            <div class="explore-btn">
                                <router-link to="/team">
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                        <ul class="social-link">
                            <li>
                                <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                            </li>
                            <li>
                                <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="expert-box">
                        <img src="../../assets/images/team/member2.webp" alt="image">
                        <div class="latest-content  d-flex">
                            <div class="content">
                                <h5>
                                    <router-link to="/team">
                                        Charlotte Amelia 
                                    </router-link>
                                </h5>
                                <p>Co-Founder</p>
                            </div>
                            <div class="explore-btn">
                                <router-link to="/team">
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                        <ul class="social-link">
                            <li>
                                <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                            </li>
                            <li>
                                <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="expert-box">
                        <img src="../../assets/images/team/member3.webp" alt="image">
                        <div class="latest-content  d-flex">
                            <div class="content">
                                <h5>
                                    <router-link to="/team">
                                        Jonathan Santiago
                                    </router-link>
                                </h5>
                                <p>Product Manager</p>
                            </div>
                            <div class="explore-btn">
                                <router-link to="/team">
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                        <ul class="social-link">
                            <li>
                                <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                            </li>
                            <li>
                                <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape03" alt="image">
    </div>
</template>

<script>
export default {
    name: 'OurTeamMembers'
}
</script>

<style lang="scss">
.expert-box {
    position: relative;
    margin-bottom: 25px;
    .latest-content {
        position: absolute;
        background: #fff;
        padding: 35px;
        left: 22px;
        bottom: 22px;
        right: 22px;
        justify-content: space-between;
        align-items: center;
        .content {
            h5 {
                font-size: 22px;
                margin-bottom: 8px;
                a {
                    color: var(--headingColor);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            p {
                margin-bottom: 0;
                color: var(--hoverColor);
            }
        }
        .explore-btn {
            a {
                padding: 15px 18px;
                border: 1px solid #EEEEEE;
                border-radius: 5px;
                font-weight: 500;
                display: inline-block;
                color: #ef5d5d;
                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                    }
                }
            }
        }
    } 
    img {
        border-radius: 5px;
    }
    ul {
        position: absolute;
        right: 40px;
        top: 140px;
        opacity: 0;
        visibility: hidden;
        list-style: none;
        transition: var(--transition);
        li {
            margin-bottom: 12px;
            a {
                display: inline-block;
                width: 32px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 50%;
                text-align: center;
                font-size: 16px;
                color: var(--mainColor);
                line-height: 37px;
                transition: var(--transition);
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    &:hover {
        ul {
            top: 40px;
            opacity: 1;
            visibility: visible;
        }
    }
}
.inner-pricing {
    .pricing-box {
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
    }
}
.expert-team-area {
    position: relative;
    .about-shape03 {
        position: absolute;
        top: 13%;
        right: 26%;
    }
}
</style>