<template>
    <div>
        <TopHeader />
        <MainNavbar />
        <SidebarModal />
        <MainBanner />
        <AboutOurCompany />
        <ServicesWeOffer />
        <WhyChooseUs />
        <PartnerLogo class="partner-logo-area pt-100 pb-75 bg-F4F8FC" />
        <!-- <HowWeWork /> -->
        <OurCaseStudy />
        <!-- <ChooseYourBestPlans /> -->
        <!-- <OurTeamMembers /> -->
        <OurClientTestimonial />
        <!-- <ContactUs /> -->
        <BlogPost />
        <!-- <FunFect /> -->
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import MainBanner from '../HomeOne/MainBanner'
import AboutOurCompany from '../HomeOne/AboutOurCompany'
import ServicesWeOffer from '../HomeOne/ServicesWeOffer'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import PartnerLogo from '../Common/PartnerLogo'
// import HowWeWork from '../HomeOne/HowWeWork'
import OurCaseStudy from '../HomeOne/OurCaseStudy'
// import ChooseYourBestPlans from '../Common/ChooseYourBestPlans'
// import OurTeamMembers from '../HomeOne/OurTeamMembers'
import OurClientTestimonial from '../HomeOne/OurClientTestimonial'
// import ContactUs from '../HomeOne/ContactUs'
import BlogPost from '../HomeOne/BlogPost'
// import FunFect from '../Common/FunFect'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'HomeOnePage',
    components: {
        TopHeader,
        MainNavbar,
        SidebarModal,
        MainBanner,
        AboutOurCompany,
        ServicesWeOffer,
        WhyChooseUs,
        PartnerLogo,
        // HowWeWork,
        OurCaseStudy,
        // ChooseYourBestPlans,
        // OurTeamMembers,
        OurClientTestimonial,
        // ContactUs,
        BlogPost,
        // FunFect,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>