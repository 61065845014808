<!-- <template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div data-tilt class="about-image">
                        <img src="../../assets/images/about/about1.webp" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="single-section about-content">
                        <span>About Our Company</span>
                        <h2>Unveiling Upraiss</h2>
                        <p>Upraiss is more than a service provider; we are collaborators, architects of success, and
                            champions of your aspirations. Ready to unlock your potential? Let's innovate, grow, and
                            succeed together. Welcome to Upraiss.</p>
                        
                        <router-link to="/about" class="default-btn">Learn More</router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/about-shape1.webp" class="about-shape2" alt="image">
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
}
</script>

<style lang="scss">
.about-content {
    margin-left: -45px;

    .last-p {
        margin-bottom: 25px;
    }
}

.about-image {
    margin-right: 45px;
    position: relative;

    .about-shape {
        position: absolute;
        top: 28px;
        left: 23%;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
    }
}

.about-area {
    position: relative;
    z-index: 1;

    .about-shape2 {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: -1;
    }
}

@media only screen and (max-width: 767px) {
    .about-image {
        margin-right: 0;
        position: relative;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}</style> --> 

<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div data-tilt class="about-image">
                        <img src="../../assets/images/about/about1.webp" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="single-section about-content">
                        <span>About Our Company</span>
                        <h2>Unveiling Upraiss</h2>
                        <p>Upraiss is more than a service provider; we are collaborators, architects of success, and
                            champions of your aspirations. Ready to unlock your potential? Let's innovate, grow, and
                            succeed together. Welcome to Upraiss.</p>
                        <!-- <p class="last-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ull
                            mattis pulvinar dapibus leoorem ipsum.</p> -->
                        <router-link to="/about" class="default-btn">Learn More</router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/about-shape1.webp" class="about-shape2" alt="image">
        <!-- WhatsApp button -->
        <a href="https://wa.me/917303205571" class="whatsapp_float" target="_blank" >
            <i class="fa-brands fa-whatsapp whatsapp-icon"></i>
  
        </a>
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
}
</script>

<style lang="scss">
.about-content {
    margin-left: -45px;

    .last-p {
        margin-bottom: 25px;
    }
}

.about-image {
    margin-right: 45px;
    position: relative;

    .about-shape {
        position: absolute;
        top: 28px;
        left: 23%;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
    }
}

.about-area {
    position: relative;
    z-index: 1;

    .about-shape2 {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: -1;
    }
}

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .about-image {
        margin-right: 0;
        position: relative;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}
</style>