<!-- <template>
    <div class="query-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 p-0">
                    <div class="query-image"></div>
                </div>
                <div class="col-lg-7 p-0">
                    <div class="query-form single-section">
                        <span>Any Query?</span>
                        <h2>Contact Us Now</h2>
                        <form @submit="handleSubmit">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Name" v-model="name">
                                    <p v-if="nameError" class="error-message">Please enter your name</p>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Last Name" v-model="name">
                                    <p v-if="nameError" class="error-message">Please enter your Last Nmae</p>
                                </div>

                               

                                
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Email Address" v-model="email">
                                    <p v-if="emailError" class="error-message">Please enter a valid email address</p>
                                </div>

                                
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Phone" v-model="mobile">
                                    <p v-if="mobileError" class="error-message">Please enter your Mobile Number</p>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Company Name" v-model="company_name">
                                    <p v-if="companyError" class="error-message">Please enter your Company name</p>
                                </div>

                                
                                    <div class="col-lg-6 col-md-6">
                                    <select id="name1" class="form-control" placeholder="job role" v-model="job_role">
                                        <option value="" disabled selected>Job Role</option>
                                        <option value="HR">HR</option>
                                        <option value="Executive">Executive</option>
                                        <option value="Business Development">Business Development</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <p v-if="jobRoleError" class="error-message">Please Select Job Role</p>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                    <select id="name1" class="form-control" v-model="job_role">
                                        <option value="" disabled selected>Query Type</option>
                                        <option value="Development">Development</option>
                                        <option value="Management and Training">Management and Training</option>
                                        <option value="Hiring">Hiring</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <p v-if="jobRoleError" class="error-message">Please Select Job Role</p>
                                    </div>


            





                                <div class="col-lg-12">
                                    <textarea cols="30" rows="10" class="form-control" placeholder="Your Message Here"
                                        v-model="message"></textarea>
                                    <p v-if="messageError" class="error-message">Please enter your message</p>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="default-btn">Submit Now</button>
                                </div>
                            </div>
                        </form>
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="project-content-shape" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            name: '',
            email: '',
            message: '',
            nameError: false,
            emailError: false,
            messageError: false
            
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault(); 
            this.nameError = false;
            this.emailError = false;
            this.messageError = false;
            

           
            if (!this.name) {
                this.nameError = true;
            }
            if (!this.email || !this.isValidEmail(this.email)) {
                this.emailError = true;
            }
            if (!this.message) {
                this.messageError = true;
            }

            if (!this.nameError && !this.emailError && !this.messageError) {
                const data = {
                    email: this.email,
                    name: this.name,
                    message: this.message,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('https://node-Upraiss.onrender.com/v0/sendEmailNotification', data, config)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    });


                this.name = '';
                this.email = '';
                this.message = '';
            }
        },
        isValidEmail(email) {

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }
    },
    name: 'ContactUs'
};

</script>

<style lang="scss">
.error-message {
    color: red;
    font-size: 12px;
}

.query-image {
    background-image: url(../../assets/images/query/query-bg1.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px 0px 0px 5px;
}

.query-form {
    padding: 100px;
    background: #F4F8FC;
    position: relative;
    border-radius: 0px 5px 5px 0px;

    .form-control {
        background: #FFFFFF;
        border-radius: 5px;
        height: 60px;
        border: none;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #888888;

        &:focus {
            box-shadow: unset;

            &::placeholder {
                color: transparent;
                transition: var(--transition);
            }
        }

        &::placeholder {
            color: #888888;
        }
    }

    textarea {
        height: 180px !important;
        padding-top: 20px;
    }

    .default-btn {
        text-align: center;
        width: 100%;
    }

    .project-content-shape {
        position: absolute;
        top: 59%;
        left: 20px;
    }
}

.query-area {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55%;
        width: 100%;
        z-index: -1;
        background: #2E2F46;
    }
}

@media only screen and (max-width: 767px) {
    .query-form {
        padding: 60px 20px;
        border-radius: 0;

        .form-control {
            height: 45px;
            font-size: 14px;
        }

        .project-content-shape {
            display: none;
        }
    }

    .query-image {
        height: 400px;
        border-radius: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .query-form {
        padding: 80px 30px;

        .project-content-shape {
            display: none;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .query-form {
        padding: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .query-form {
        padding: 65px 50px;
    }
}
</style> -->

<template>
    <div class="query-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 p-0">
                    <div class="query-image"></div>
                </div>
                <div class="col-lg-7 p-0">
                    <div class="query-form single-section">
                        <span>Any Query?</span>
                        <h2>Contact Us Now</h2>
                        <form @submit="handleSubmit">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Name" v-model="name">
                                    <p v-if="nameError" class="error-message">Please enter your name</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Last Name" v-model="lastName">
                                    <p v-if="lastNameError" class="error-message">Please enter your Last Name</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Email Address" v-model="email">
                                    <p v-if="emailError" class="error-message">Please enter a valid email address</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Phone" v-model="mobile">
                                    <p v-if="mobileError" class="error-message">Please enter your Mobile Number</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Company Name" v-model="company_name">
                                    <p v-if="companyError" class="error-message">Please enter your Company name</p>
                                </div>
                                <!-- <div class="col-lg-6 col-md-6">
                                    <select id="name1" class="form-control" placeholder="" v-model="job_role">
                                        <option value="">Job Role</option>
                                        <option value="HR">HR</option>
                                        <option value="Executive">Executive</option>
                                        <option value="Business Development">Business Development</option>
                                        <option value="other">other</option>
                                    </select>
                                    <p v-if="jobRoleError" class="error-message">Please Select Job Role</p>
                                </div> -->
                                <div class="col-lg-6 col-md-6">
                                    <select id="name1" class="form-control" v-model="job_role">
                                        <option value="" disabled>Job Role</option>
                                        <option value="HR">HR</option>
                                        <option value="Executive">Executive</option>
                                        <option value="Business Development">Business Development</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <p v-if="jobRoleError" class="error-message">Please Select Job Role</p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <select id="name1" class="form-control" v-model="query_type">
                                        <option value="" disabled>Query Type</option>
                                        <option value="Development">Development</option>
                                        <option value="Management and Training">Management and Training</option>
                                        <option value="Hiring">Hiring</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <p v-if="queryTypeError" class="error-message">Please Select Query Type</p>
                                </div>
                                <!-- Add validation for other form inputs -->
                                <div class="col-lg-12">
                                    <textarea cols="30" rows="10" class="form-control" placeholder="Your Message Here" v-model="message"></textarea>
                                    <p v-if="messageError" class="error-message">Please enter your message</p>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="default-btn">Submit Now</button>
                                </div>
                            </div>
                        </form>
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="project-content-shape" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            name: '',
            lastName: '',
            email: '',
            mobile: '',
            company_name: '',
            job_role: '', // Initial value should be an empty string to show the placeholder
            query_type: '', // Initial value should be an empty string to show the placeholder
            message: '',
            nameError: false,
            lastNameError: false,
            emailError: false,
            mobileError: false,
            companyError: false,
            jobRoleError: false,
            queryTypeError: false,
            messageError: false
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault(); // Prevent form submission
            this.nameError = !this.name;
            this.lastNameError = !this.lastName;
            this.emailError = !this.isValidEmail(this.email);
            this.mobileError = !this.mobile;
            this.companyError = !this.company_name;
            this.jobRoleError = !this.job_role;
            this.queryTypeError = !this.query_type;
            this.messageError = !this.message;

            // Proceed with API request if form is valid
            if (!this.nameError && !this.lastNameError && !this.emailError && !this.mobileError && !this.companyError && !this.jobRoleError && !this.queryTypeError && !this.messageError) {
                const data = {
                    name: this.name,
                    lastName: this.lastName,
                    email: this.email,
                    mobile: this.mobile,
                    company_name: this.company_name,
                    job_role: this.job_role,
                    query_type: this.query_type,
                    message: this.message
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('https://node-Upraiss.onrender.com/v0/sendEmailNotification', data, config)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                // Reset form fields
                this.name = '';
                this.lastName = '';
                this.email = '';
                this.mobile = '';
                this.company_name = '';
                this.job_role = '';
                this.query_type = '';
                this.message = '';
            }
        },
        isValidEmail(email) {
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }
    },
    name: 'ContactUs'
};
</script>

<style lang="scss">
.error-message {
    color: red;
    font-size: 12px;
}

.query-image {
    background-image: url(../../assets/images/query/query-bg1.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px 0px 0px 5px;
}

.query-form {
    padding: 1000px;
    background: #F4F8FC;
    position: relative;
    border-radius: 0px 5px 5px 0px;

    .form-control {
        background: #FFFFFF;
        border-radius: 5px;
        height: 60px;
        border: none;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #888888;

        &:focus {
            box-shadow: unset;

            &::placeholder {
                color: transparent;
                transition: var(--transition);
            }
        }

        &::placeholder {
            color: #888888;
        }
    }

    textarea {
        height: 180px !important;
        padding-top: 20px;
    }

    .default-btn {
        text-align: center;
        width: 100%;
    }

    .project-content-shape {
        position: absolute;
        top: 59%;
        left: 20px;
    }
}

.query-area {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55%;
        width: 100%;
        z-index: -1;
        background: #2E2F46;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .query-form {
        padding: 60px 20px;
        border-radius: 0;

        .form-control {
            height: 45px;
            font-size: 14px;
        }

        .project-content-shape {
            display: none;
        }
    }

    .query-image {
        height: 400px;
        border-radius: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .query-form {
        padding: 80px 30px;

        .project-content-shape {
            display: none;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .query-form {
        padding: 30px;
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .query-form {
        padding: 65px 50px;
    }
}
</style>
