<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <!-- <PageBanner pageTitle="Contact Us" /> -->
        <ContactContent />
        <MapContent />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
// import PageBanner from '../Common/PageBanner'
import ContactContent from '../Contact/ContactContent'
import MapContent from '../Contact/MapContent'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'ContactPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        // PageBanner,
        ContactContent,
        MapContent,
        MainFooter,
    }
}
</script>