<template>
       <!-- <div class="banner">
    <img :src="require('../../assets/images/banner/inner-bg.webp')" alt="Banner Image">
    </div> -->

        <div class="latest-area pt-100 pb-75" >
     
        <!-- <div class="latest-area pt-100 pb-75" :style="{ backgroundImage: `url(${require('../../assets/images/banner/inner-bg.webp')})` }"> -->
        <div class="container">
            <div class="section-title">
                <p class="title-tag">
                    <i class="flaticon-full-stop"></i>
                    OUR CASE STUDY
                </p>
                <h2>Our Latest Incredible Client's Case Study</h2>
            </div>
            <div class="row">
                <div class="col-lg-6" v-for="project in projects" :key="project.id">
                    <div class="frist-widget" >
                        <div class="row" >
                            <div class="col-lg-12">
                                <div class="latest-box">
                                    <img :src="project.showImage" alt="image">
                                    <div class="latest-content  d-flex">
                                        <div class="content">
                                            <h5>
                                                <router-link :to="`${project.projectDetails}`">
                                                    {{ project.title }}
                                                </router-link>
                                            </h5>
                                            <p>{{project.subtitle}}</p>
                                        </div>
                                        <div class="explore-btn">
                                            <router-link :to="`${project.projectDetails}`">Exolore
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="result-pagination search-pagination">
                <!-- <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider2.svg" alt="svg">
                                </span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider1.svg" alt="svg">
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseStudy',
    data() {
        return {
            projects: [
                {
                    id: 1,
                    showImage: require("../../assets/images/projects/project4.webp"),
                    title: "Khawishh",
                    projectDetails: "case-study-details?service=project1",
                    subtitle: "Fashion Store",

                },
                {
                    id: 2,
                    showImage: require("../../assets/images/projects/project5.webp"),
                    title: "BrainWoo",
                    projectDetails: "case-study-details?service=project2",
                    subtitle: "Education Platform",
                },
                {
                    id: 3,
                    showImage: require("../../assets/images/projects/project6.webp"),
                    title: "ITP Media",
                    subtitle: "Media Company",
                    projectDetails: "case-study-details?service=project3",
                },
                {
                    id: 4,
                    showImage: require("../../assets/images/projects/project1.webp"),
                    title: "Growpital",
                    projectDetails: "case-study-details?service=project4",
                    subtitle: "Agro Based Investment",

                },
                {
                    id: 5,
                    showImage: require("../../assets/images/projects/project3.webp"),
                    title: "EastMojo",
                    projectDetails: "case-study-details?service=project5",
                    subtitle: "News App",

                },
                {
                    id: 6,
                    showImage: require("../../assets/images/projects/project2.webp"),
                    title: "BitMemoir",
                    projectDetails: "case-study-details?service=project6",
                    subtitle: "BloackChain Certifications",

                },
            ]
        }
    }
}
</script>

<style lang="scss">
.latest-area {
    position: relative;
    z-index: 1;

    .section-title {
        text-align: center;
        margin-bottom: 30px;
        max-width: 705px;
        margin-right: auto;
        margin-left: auto;
    }
}
.second-widget {
    .view-project {
        text-align: end;
        margin-bottom: 50px;
        padding-right: 10px;
        a {
            font-weight: 500;
            color: #ef5d5d;
            text-decoration: underline;
            z-index: 1;
            position: relative;
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                    transition: var(--transition);
                }
            }
            &::before {
                position: absolute;
                content: "";
                top: -17px;
                left: -30px;
                bottom: 0;
                width: 60px;
                height: 60px;
                z-index: -1;
                border-radius: 50%;
                background: #F4F8FC;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 5%);
            }
        }
    }
}
.latest-box {
    position: relative;
    margin-bottom: 25px;
    padding: 10px;
    .latest-content {
        position: absolute;
        background: #fff;
        padding: 40px;
        left: 40px;
        bottom: 40px;
        right: 40px;
        justify-content: space-between;
        align-items: center;
        .content {
            h5 {
                font-size: 22px;
                margin-bottom: 8px;
                a {
                    color: var(--headingColor);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .explore-btn {
            a {
                padding: 15px 29px;
                border: 1px solid #EEEEEE;
                border-radius: 5px;
                font-weight: 500;
                display: inline-block;
                color: #ef5d5d;
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                        transition: var(--transition);
                    }
                }
            }
        }
    } 
    img {
        border-radius: 5px;
    }
}
</style>