<template>
    <div class="service-area pt-100 pb-75 bg-F4F8FC">
        <div class="container">
            <div class="section-title">
                <ul class="section-style">
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                    <li>
                        Services We Offer
                    </li>
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                </ul>
                <h2>Our Main Services</h2>
            </div>

            <div class="row">
                <div 
                    class="col-lg-4 col-md-6" 
                    data-aos="fade-up" 
                    data-aos-duration="1000"
                    v-for="service in services"
                    :key="service.id"
                >
                    <div class="service-card service-card-style2">
                        <div class="service-icon">
                            <img :src="service.image" class="touch" alt="svg">
                        </div>
                        <h2>{{service.number}}</h2>
                        <div class="service-content">
                            <h4>
                                <router-link to="/service-details">{{service.title}}</router-link>
                            </h4>
                            <p>{{service.desc}}</p>
                            <router-link to="/service-details" class="read-more">Read More
                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                            </router-link>
                        </div>
                        <img src="../../assets/images/shapes/service-shape2.webp" class="service-hide" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/service-shape1.webp" class="service-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'ServicesWeOffer',
    data (){
        return {
            services: [
                {
                    id: 1,
                    image: require("../../assets/images/svgs/touch1.svg"),
                    number: '01',
                    title: "IT Consultation",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
                {
                    id: 2,
                    image: require("../../assets/images/svgs/touch2.svg"),
                    number: '02',
                    title: "Software Development",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
                {
                    id: 3,
                    image: require("../../assets/images/svgs/touch3.svg"),
                    number: '03',
                    title: "Data Security",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
                {
                    id: 4,
                    image: require("../../assets/images/svgs/touch4.svg"),
                    number: '04',
                    title: "AI Machine Learning",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
                {
                    id: 5,
                    image: require("../../assets/images/svgs/touch5.svg"),
                    number: '05',
                    title: "Coupon Management",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
                {
                    id: 6,
                    image: require("../../assets/images/svgs/touch6.svg"),
                    number: '06',
                    title: "Membership Subscription",
                    desc: "Lorem ipsum dolor sit amet consectetu radi dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.",
                },
            ]
        }
    }
}
</script>

<style lang="scss">
.service-card-style2 {
    &:before {
        background: #2E2F46;
    }
    .service-hide {
        position: absolute;
        z-index: -1;
        top: -53px;
        right: 0;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        .service-hide {
            opacity: 1;
            visibility: visible;
        } 
        .service-icon {
            .shown {
                opacity: 1;
                visibility: visible;
            }
        }
        h2 {
            background:  linear-gradient(180deg, #ef5d5d 0%, #FF414B 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity: 80%;
        }
    }
    .service-icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        text-align: center;
        line-height: 100px;
        background: linear-gradient(180deg, #ef5d5d 0%, #FF414B 100%);
    }
    h2 {
        -webkit-text-stroke: 1px #DDDDDD;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 64px;
        color: transparent;
        position: absolute;
        right: 40px;
        top: 40px;
        transition: var(--transition);
    }
}
</style>