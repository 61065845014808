export const services = {
  "web development": {
    title: "Web Development",
    paragraph1:
      "Web Development combines the process of planning, designing with the use of technology to build and maintain a website that is functional, looks visually appealing and is interactive. It includes Database Management, server - site scripting, client - site scripting and web designing.",
    paragraph2: "",
    hading2: "We provide you the following under Web Development -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Front - End Framework" },
      { id: 2, li: "Back - End Framework" },
      { id: 3, li: "Application Programming Interfaces (APIs)" },
      { id: 4, li: "Web Servers and Hosting" },
    ],
    banner: '../../assets/images/video/service-details.jpg',
    // src: "../../assets/images/video/service-details.jpg",
    paragraph3: "",
    quote:
      "The web development process is an art of converting imagination into reality",
    pragraph4:
      "Our team assures you timely delivery of the task and we stay connected with you at every step of the process to assure that all things are going on track and meet your expectations.",
  },
  "app development": {
    title: "App Development",
    paragraph1:
      "App Development is the process of creating software applications that can be used on mobile devices and tablets, which involves the process of designing, coding, testing and deploying applications that run on operating systems such as iOS and Android.",
    paragraph2: "",
    hading2: "We provide the following under App Development -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Native and Hybrid App Development" },
      { id: 2, li: "App Store Deployment" },
      { id: 3, li: "App Maintainence and Analytics" },
      { id: 4, li: "UI and UX" },
      { id: 5, li: "Cross Platform App Development" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "App development is not just about coding; it's about creating experiences that captivate and delight users.",
    pragraph4:
      "We assure you to create an immersive experience that fits in the palm of your hand.",
  },
  "uiux design": {
    title: "UI/UX Design",
    paragraph1:
      "We combine the process of creating user interfaces and experiences for all your applications. While focusing on the visual aspects and aesthetics whilst coming up with the overall feel, usability and the satisfaction of the user interacting with the product.",
    paragraph2: "",
    hading2: "A few key Aspects under UI/UX Design are mentioned Below -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "User Research" },
      { id: 2, li: "Wireframing and Protottyping" },
      { id: 3, li: "Microinteractions" },
      { id: 4, li: "User Testing and Feedback" },
      { id: 5, li: "Continious Improvements" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "Design is not just a visual layer; it's an emotional and intellectual connection between the product and the user.",
    pragraph4:
      "Our goal is to create a seamless, intuitive and delightful experience for the users.",
  },
  "it resources": {
    title: "IT Resources",
    paragraph1:
      "IT Resources refers to our in house team coming to your office/workspace to help you build or maintain your resources providing you with the best background technical support on all fronts.",
    paragraph2: "",
    hading2:
      "It includes use of miltiple softwares for your software, a few of them are listed below -",
    whatYouWillGet: "Front End and Back End Development - ",
    listOffering: [
      { id: 1, li: "Front End Development - React, Sass, HTML5, vue.JS, Angular " },
      { id: 2, li: "Back End Development -NodeJS, MYSQL, MongoDB, PostgreSQL" },
      { id: 3, li: "React Js" },
      { id: 4, li: "Github" },
      { id: 5, li: "Incident Management and Retrospect" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "The greatest benefits of AI in IT Resources will come from augmenting human capabilities, not replacing them.",
    pragraph4:
      "Our team assures you to give the best on site results for your requirements within a limited time span. It is the flexibility of softwares and location that makes us unique from others and we stand out by giving you customized solutions for your needs.",
  },
  "artificial intllegence": {
    title: "Artificial Intellegence",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in rArtificial Intelligence as a service is a cloud based offering wherein we leaverage the power of Machine Learning, Natural Language Processing, data analytics through API's or  pre built models.",
    paragraph2:
      "Upraiss offers you a cost effective and convenient method to harness the power of AI without the complexities and resource requirements of building and managing their own infrastructure.",
    hading2: "We provide you the following benefits under this segment -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Rapid IT Development" },
      { id: 2, li: "CBetter Accessability and Scalability" },
      {
        id: 3,
        li: "Continious Innovation and integration of latest AI Tools and Processes",
      },
      { id: 4, li: "AI Bots and Virtual Assistant Creation" },
      { id: 5, li: "Data Analysis and Insights " },
    ],
    banner: "",
    paragraph3:
      "Our aim is to ease the customer journey process and make it as smooth from them as possible, be it a query, getting more traffic or resolving any issue, it'll be a one stop solution to their problems.",
    quote:
      "Artificial intelligence will empower us to do more, achieve more, and create more. It is a tool that expands our horizons and unlocks new possibilities.",
    pragraph4:
      "Our team assures you timely delivery of the task and we stay connected with you at every step of the process to assure that all things are going on track and meet your expectations.",
  },
  blockchain: {
    title: "Blockchain",
    paragraph1:
      "Upraiss allows businesses to leverage the benefits of blockchain, such as decentralization, immutability, and transparency, without the need for extensive in-house expertise or infrastructure investment.",
    paragraph2:
      "We  provide a platform or framework that simplifies the development, deployment, and management of blockchain applications.",
    hading2: "We provide you the following under Blockchain services -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Infrastructure" },
      { id: 2, li: "Network Management" },
      { id: 3, li: "Security and Stability" },
      { id: 4, li: "Support and Training" },
      { id: 5, li: "Developer Options and Customizations" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "Blockchain is the future of cybersecurity; it has the potential to revolutionize the way we secure our digital assets",
    pragraph4:
      "Our aim is to give your organization a secured and a efficient network to communicate and share all sorts of documents and data without any hassle.",
  },
  "dev ops": {
    title: "DevOps Services",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in order to streamline the software development and the deployment process. The team’s goal is to foster collaboration, improve efficiency, and enhance the overall quality and reliability of software delivery.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Configuration Management" },
      { id: 2, li: "Version Control" },
      { id: 3, li: "Continious Delivery and Intergration" },
      { id: 4, li: "Incident Management and Retrospect" },
      { id: 5, li: "Aglie and Lean Methodologies" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "DevOps is the union of people, process, and technology to enable continuous delivery of value to customers.",
    pragraph4:
      "In the modern world, DevOps Services unlock the key to agility, efficiency and innovation and that is what drives us to work even harder for your applications.",
  },
  "qa tester": {
    title: "QA Testing",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in order to streamline the software development and the deployment process. The team’s goal is to foster collaboration, improve efficiency, and enhance the overall quality and reliability of software delivery.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Functional Testing" },
      { id: 2, li: "Performance Testing" },
      { id: 3, li: "Bug Reporting" },
      { id: 4, li: "User Interface Testing" },
      { id: 5, li: "Test planning and Test Case Developments" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "Quality is not an act, it is a habit.",
    pragraph4:
      "QA testing is an iterative process that involves continuous testing, bug fixing, and retesting until the software meets the desired quality standards.",
  },
  "maintenance": {
    title: "Maintenance",
    paragraph1:
      "Upraiss offers you high quality maintenance for all your softwares and applications assuring you and your user smooth functioning and  a dfelightful experience.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "IT Infrastructure Maintenance" },
      { id: 2, li: " Updates in the services/ products" },
      { id: 3, li: "Software Maintenence" },
      { id: 4, li: "IT Consulting" },
      { id: 5, li: "Security Management" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "DevOps is the union of people, process, and technology to enable continuous delivery of value to customers.",
    pragraph4:
      "In the modern world, DevOps Services unlock the key to agility, efficiency and innovation and that is what drives us to work even harder for your applications.",
  },
};
