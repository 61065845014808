<template>
    <div class="case-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="case-big-widget">
                        <div class="case-details-image">
                            <img :src="projectDetail.banner" alt="image">
                        </div>
                        <div class="upgrade">
                            <!-- <h2>{{projectDetail.title}}</h2> -->
                            <p> {{ projectDetail.about}} </p>
                        </div>
                        <div class="description">
                            <ul v-for="item in projectDetail.listOffering" :key="item.id">
                                <li>
                                    <i class="flaticon-full-stop"></i>
                                    {{ item.li }}
                                </li>                       
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="case-details-small-widget">
                        <div class="details-box">
                            <h4>Case Details</h4>
                            <!-- <ul class="details-list">
                                <li>
                                    Name:  <span>Infrastructure Upgrade</span>
                                </li>
                                <li>
                                    Category:   <span>Game Development</span>
                                </li>
                                <li>
                                    Client:     <span>VnetTheme65.com</span>
                                </li>
                                <li>
                                    Website:  <span>VnetTheme76.com</span>
                                </li>
                                <li>
                                    Date:    <span>17 Nov 2022</span>
                                </li>
                            </ul> -->
                            <div class="social-box">
                                <h5>Share On :</h5>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="dowonload">
                            <h4>Brochures</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adi ullamcorper mattis pulvinar dapibus leooremip adipiscing elitut ulvinar dapibus leo.</p>
                            <button type="submit" class="default-btn">Download Now 
                                <i class="flaticon-cloud-computing"></i>
                            </button>
                        </div>
                        <div class="questions">
                            <a href="tel:3128959800" class="icon">
                                <i class="flaticon-phone-call-1"></i>
                            </a>
                            <h3>Have any Questions? Call us Today!</h3>
                            <a href="tel:3128959800">+91-7303205771</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { project } from "../../constants/project";

export default {
    created() {
        this.getParam();
    },
    methods: {
        getParam() {
            // Accessing route params in the created hook
            const { service } = this.$route.query;
            this.projectDetail = service ? project[service?.toLocaleLowerCase()] : project["blog2"]
        },
    },
    data() {
        return {
            projectDetail: null,
        }
    },
    name: 'CaseStudyDetails'
}
</script>

<style lang="scss">
.case-details-area {
    h2 {
        font-size: 25px;
        letter-spacing: -0.03em;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 25px;
        letter-spacing: -0.03em;
        margin-bottom: 15px;
    }
}
.case-big-widget {
    .case-details-image {
        margin-bottom: 30px;
        img {
            border-radius: 5px;
        }
    }
}
.upgrade {
    margin-bottom: 30px;
}
.description {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 18px;
            i {
                position: absolute;
                left: -23px;
                top: -24px;
                font-size: 58px;
                color: #ef5d5d;
            }
        }
    }
}
.details-box {
    padding: 30px 25px 40px 40px;
    background: #F4F8FC;
    border-radius: 5px;
    margin-bottom: 30px;
    h4 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .details-list {
        padding-left: 0;
        margin-bottom: 40px;
        list-style: none;
        li {
            position: relative;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 16px;
            color: var(--headingColor);
            span {
                position: absolute;
                right: 0;
                top: 0;
                font-weight: 400;
                color: var(--bodyColor);
            }
        }
    }
    .social-box {
        h5 {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
        }
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                padding-right: 10px;
                &:last-child {
                    padding-right: 0;
                }
                a {
                    width: 55px;
                    height: 55px;
                    background-color: var(--whiteColor);
                    border-radius: 50%;
                    line-height: 60px;
                    font-size: 22px;
                    display: inline-block;
                    text-align: center;
                    color: var(--mainColor);
                    &:hover {
                        background-color:  var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}
.dowonload {
    padding: 30px 25px 40px 40px;
    background: #F4F8FC;
    border-radius: 5px;
    margin-bottom: 30px;
    p {
        margin-bottom: 20px;
    }
    h4 {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .default-btn {
        i {
            color: var(--whiteColor);
            margin-left: 4px;
        }
    }
}
.questions {
    text-align: center;
    padding: 65px 35px 85px 35px;
    background: linear-gradient(89.39deg, #ef5d5d -25.46%, #FF414B 133.39%);
    border-radius: 5px;
    .icon {
        width: 60px;
        height: 60px; 
        background-color: var(--whiteColor);
        border-radius: 50%;
        text-align: center;
        color: var(--mainColor);
        margin: 20px 50px 20px 20px;
        position: relative;
        display: inline-block;
        line-height: 66px;
        font-size: 22px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 50px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            z-index: -1;
            -webkit-animation: ripple 5s linear infinite;
            animation: ripple 5s linear infinite;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 20px;
        color: #ffffff;
        max-width: 259px;
        margin-left: auto;
        margin-right: auto;
    }
    a {
        font-size: 40px;
        color: var(--whiteColor);
        font-weight: 700;
        &:hover {
            color: var(--hoverColor);
        }
    }
}
</style>