
<template>
    <div class="inner-banner-area">
        <div class="container-fluid">
            <h1>{{pageTitle}}</h1>
            <ul class="inner-list">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>{{pageTitle}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageBanner',
    props: ['pageTitle']
}
</script>

<style lang="scss">
.inner-banner-area {
    background-image: url(../../assets/images/banner/inner-bg.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 200px;
    h1 {
        font-size: 64px;
        margin-bottom: 5px;
        color: var(--whiteColor);
    }
}
.inner-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
        display: inline-block;
        padding-right: 22px;
        position: relative;
        a {
            color: #CCCCCC;
        }
        &:last-child {
            padding-right: 0;
            color: var(--hoverColor);
            &::before {
                display: none;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 5px;
            right: 10px;
            transform: rotate(23deg);
            bottom: 0;
            height: 15px;
            width: 1px;
            background-color: #CCCCCC;
        }
    }
}
</style>