<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="faq-image">
                        <img src="../../assets/images/faq/faq1.webp" class="faq-1" alt="image">
                        <img src="../../assets/images/faq/faq2.webp" class="faq-2" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="accordion-area single-section">
                        <span>FAQ</span>
                        <h2>Frequently Ask Questions</h2>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is The Purpose of a Consultant?
                                </button>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus llamo
                                    rper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetur adipiscingel 
                                    elit tellu luctus pulvinar dapibus leo.
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What Are The Advantages of Being a Consultant?
                                </button>
                                <div id="collapseTwo" class="accordion-collapse collapse"   data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus llamo
                                    rper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetur adipiscingel 
                                    elit tellu luctus pulvinar dapibus leo.
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How is Working in Consulting?
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus llamo
                                    rper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetur adipiscingel 
                                    elit tellu luctus pulvinar dapibus leo.
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                Is Consulting a Good Career?
                                    </button>
                                <div id="collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus llamo
                                        rper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetur adipiscingel 
                                        elit tellu luctus pulvinar dapibus leo.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                            What Attracts You To The Role of a Consultant?
                                </button>
                            <div id="collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus llamo
                                    rper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetur adipiscingel 
                                    elit tellu luctus pulvinar dapibus leo.
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaqContent'
}
</script>

<style lang="scss">
.faq-image {
    position: relative;
    .faq-2 {
        position: absolute;
        left: 0;
        z-index: -1;
        bottom: 0;
    }
    .about-shape {
        position: absolute;
        top: 176px;
        right: 160px;
    }
}
.accordion-area {
    margin-left: 55px;
    button.accordion-button {
        background: #F4F8FC;
        border-radius: 5px;
        border: none;
        font-weight: 500;
        font-size: 20px;
        transition: var(--transition);
        &:focus {
            box-shadow: unset;
        }
    }
    .accordion-item {
        border: none;
        margin-bottom: 20px;
    }
    .accordion-body {
        padding: 25px 25px 25px 25px;
    }
    .accordion-button:not(.collapsed) {
        background: linear-gradient(90deg, #ef5d5d -11.52%, #FF414B 111.24%);
        color: var(--whiteColor);
        &:focus {
            box-shadow: unset;
        }
    }
    .accordion-button::after {
        font-family: flaticon !important;
        content: "\f101";
        top: 50%;
        background-image: none;
        position: absolute;
        right: 30px;
        font-size: 16px;
        transform: translateY(-11px);
    }
    .accordion-button:not(.collapsed) {
        &::after {
            content: "\f102";
        top: 50%;
        background-image: none;
        position: absolute;
        right: 30px;
        font-size: 16px;
        transform: translateY(-11px);
        }
    }
}
</style>