<!-- <template>
    <div>
        <div class="container">
            <div class="inner-partner">
                <div class="partner-slider">
                    <carousel :autoplay="5000" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide v-for="partner in partners" :key="partner.id">
                            <div class="partner-logo">
                                <img :src="partner.image" alt="logo">
                            </div>
                        </Slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'PartnerLogo',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        partners: [
            {
                id: 1,
                image: require("../../assets/images/upload/1.png")
            },
            {
                id: 2,
                image: require("../../assets/images/upload/2.png")
            },
            {
                id: 3,
                image: require("../../assets/images/upload/3.png")
            },
            {
                id: 4,
                image: require("../../assets/images/upload/4.png")
            },
            {
                id: 5,
                image: require("../../assets/images/upload/5.png")
            },
            {
                id: 6,
                image: require("../../assets/images/upload/6.png")
            },
            {
                id: 7,
                image: require("../../assets/images/upload/7.png")
            },
            {
                id: 8,
                image: require("../../assets/images/upload/8.webp")
            },
            {
                id: 9,
                image: require("../../assets/images/upload/9.png")
            },
            {
                id: 10,
                image: require("../../assets/images/upload/10.webp")
            },
            {
                id: 11,
                image: require("../../assets/images/upload/11.png")
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
    }),
})
</script>

<style lang="scss">
.partner-logo {
    margin-right: 20px;
    margin-bottom: 25px;
    position: relative;
}

.partner-logo-area {
    .carousel {
        .carousel__viewport {
            .carousel__track {
                .carousel__slide {
                    img {
                        display: block;
                        width: 150px;
                        margin: auto;
                    }
                }
            }
        }
    }
}

.partner-style3 {
    .inner-partner {
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding: 70px 50px 45px 50px;
        position: relative;
        top: -100px;
        margin-bottom: -100px;
    }
}

.inner-partner-logo {
    border-top: solid 1px var(--borderColor);
}
</style> -->



<template>
    <div class="partner-area pt-100 pb-75 bg-F4F8FC">
      <div class="container">
        <div class="section-title">
          <h2>Our Partners</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6" v-for="partner in partners" :key="partner.id">
            <div class="partner-card">
              <div class="partner-logo">
                <img :src="partner.image" alt="logo">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PartnerLogo',
    data() {
      return {
        partners: [
          {
            id: 1,
            image: require("../../assets/images/upload/1.png"),
          },
          {
            id: 2,
            image: require("../../assets/images/upload/2.png"),
          },
          {
            id: 3,
            image: require("../../assets/images/upload/3.png"),
          },
          {
            id: 4,
            image: require("../../assets/images/upload/4.png"),
          },
          {
            id: 5,
            image: require("../../assets/images/upload/5.png"),
          },
          {
            id: 6,
            image: require("../../assets/images/upload/6.png"),
          },
          {
            id: 7,
            image: require("../../assets/images/upload/7.png"),
          },
        //   {
        //     id: 8,
        //     image: require("../../assets/images/upload/8.webp"),
        //   },
          {
            id: 9,
            image: require("../../assets/images/upload/9.png"),
          },
          {
            id: 10,
            image: require("../../assets/images/upload/10.webp"),
          },
        //   {
        //     id: 11,
        //     image: require("../../assets/images/upload/11.png"),
        //   },
        ],
      };
    },
  };
  </script>
  
  <style lang="scss">
  .partner-area {
    position: relative;
    z-index: 1;
  
    .partner-card {
      background: #ffffff;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 25px;
      text-align: center;
      transition: var(--transition);
    }
  
    .partner-logo {
      img {
        max-width: 100%;
        height: auto;
        transition: var(--transition);
        width: 100px; /* Fixed width for consistency */
        height: 100px; /* Fixed height for consistency */
        object-fit: contain; /* Ensures the image fits within the bounds without distortion */
      }
    }
  }
  </style>
  